.table {
  display: flex;
  padding: 0 15px;
  flex-flow: column nowrap;
  flex: 1 1 auto;
  @include theme-aware('background-color', 'backgroundInfoBlock');
}

.futureEpoch .td {
  a {
    @include theme-aware('color', 'futureEpoch');
  }

  time {
    @include theme-aware('color', 'futureEpoch');
  }
}

.th > .td {
  font-size: 14px;
  font-weight: normal;
  white-space: normal;
  justify-content: center;
  @include theme-aware('color', 'titleInfoBlock');
  text-transform: uppercase;
}

.tr {
  width: 100%;
  display: flex;
  flex-flow: row nowrap;
  border-bottom: 1px solid $secondGrey;
}

.tr:last-child {
  border-bottom: none;
}

.td:first-child {
  justify-content: flex-start;
}

.td:last-child {
  justify-content: flex-end;
  flex-grow: 1;
}

.td {
  font-size: 16px;
  display: flex;
  flex-flow: row nowrap;
  flex-grow: 1;
  flex-basis: 0;
  margin: 0 15px;
  padding: 22px 0 10px 0;
  min-width: 0;
  justify-content: center;
  white-space: nowrap;
  @include theme-aware('color', 'tableTextColor');

  .statusIcon {
    margin-right: 10px;
  }

  .arrow {
    display: none;
    position: absolute;
    right: 280px;
  }
}

.preloader {
  height: 13px;
  width: 100%;
  text-indent: -9999px;
  border-radius: 10px;
  @include theme-aware('background-color', 'tableTextColor');
  animation: light-pulse 1.5s infinite ease-in-out;
}

@keyframes light-pulse {
  0% {
    background-color: $preloaderTextColor70;
  }
  50% {
    background-color: $preloaderTextColor100;
  }
  100% {
    background-color: $preloaderTextColor70;
  }
}

@media (max-width: 1150px) {
  .table {
    overflow-x: scroll;
  }
  .responsive-table {
    width: 1000px;
  }
}

.pagination-wrap {
  padding: 15px 15px;
  @include theme-aware('background-color', 'backgroundInfoBlock');
}

.pagination {
  display: flex;
  justify-content: space-between;
  list-style: none;
  cursor: pointer;
}

.pagination_link {
  padding: 8px 16px;
  text-decoration: none;
}

.pagination_link--active {
  a {
    background-color: $green;
    color: white;
  }
  a:hover {
    background-color: $green;
  }
}

.pagination_link:hover {
  background-color: $secondGrey;
}

.pagination_link--disabled {
  a {
    color: $textColor;
    cursor: default;
  }
  a:hover {
    background-color: initial;
  }
}
